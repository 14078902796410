<template>
  <div class="main startanimation disappear">
    <h1 class="title centered unselectable"> SUPER MARIO MAKER <span class="secondarycolor"> INFO </span> </h1>
    <div class="circle centered circleout"></div>
  </div>
</template>

<script>
export default {
  name: 'StartAnimation',
  components: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>
