<template>
  <div class="recentsearches">
    <div class="content scrollbar">
      <div class="coursecard" v-for="course in courses" :key="course.code" v-on:click="this.$parent.getCourse(course.code)">
        <div class="topbar">
          <div class="centered">
            <h3>{{course.name}}</h3>
          </div>
        </div>
        <div class="container flexcontainer" style="flex-wrap: nowrap">
          <div class="thumbnail">
            <div class="p169"></div>
            <img v-bind:src="this.$parent.getLevelThumbnail(course.code)">
          </div>
          <div class="levelinfo flexgrow">
            <div class="flexcontainer">
              <div class="heart icon">
                <fa icon="heart"/>
              </div>
              <div class="flexcontainer" style="align-content: center">
                <h2> {{course.hearts}} </h2>
              </div>
              <div class="icon">
                <fa icon="heart-broken"/>
              </div>
              <div class="flexcontainer" style="align-content: center">
                <h2> {{course.boos}} </h2>
              </div>
            </div>
            <div class="flexcontainer">
              <div class="icon">
                  <fa icon="stopwatch"/>
              </div>
              <div class="flexcontainer" style="align-content: center">
                <h3> {{course.world_record != 0 ? this.$parent.getPrettyTime(course.world_record) : "uncleared"}} </h3>
              </div>
            </div>
            <div class="flexcontainer" style="position: relative">
              <div class="centered" style="width: 100%; text-align: center">
                <h3>{{course.code}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loadbutton" v-on:click="getRecentSearches(this.pagenumber + 1)">
        <div class="loadmore centered unselectable" v-if="!fetching && !end">
          <h2> Load More </h2>
        </div>
        <div class="loading centered">
          <fa class="rotating rotate" icon="sync-alt" style="height: 100%; width: 100%" v-if="fetching"/>
        </div>
        <div class="centered unselectable" style="width: 100%" v-if="!fetching && end">
          <h2> End has been reached </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      courses: [],
      fetching: false,
      page: 0,
      pagesize: 20,
      end: false
    }
  },
  name: 'RecentSearches',
  components: {
  },
  methods: {
    getRecentSearches(pagenumber = 0) {
      if (this.fetching)
        return;
      this.fetching = true;
      axios
        .get('api/recent_searches.php/?pagenumber=' + pagenumber + '&pagesize=' + this.pagesize + '&datetime=' + this.$parent.recentSearches.datetime, {
        })
        .then(response => {
          this.fetching = false;
          let courses = response.data;

          for (let i = 0; i < courses.length; i++) {
            console.log(courses[i])
            this.courses.push(courses[i]);
          }

          if (courses.length < this.pagesize)
            this.end = true;
          
          this.pagenumber = pagenumber;
        })
        .catch(error => {
          this.fetching = false;
          console.log('There was an error: ', error.response)

          console.log(error.response.data)
          alert("Something went wrong :(");
        })
    },
  },
  created() {
  },
  mounted() {
    axios.defaults.baseURL = 'https://smm2.info/'
    if (this.$parent.recentSearches.datetime == null)
      this.$parent.recentSearches.datetime = new Date().toISOString().slice(0, 19).replace('T', ' ');
  }
}
</script>

<style>
  @import '../assets/css/recentsearches.css';
</style>