<template>
  <StartAnimation v-if="startAnimation"/>
  <div class="main">
    <div class="centered" style="width: 100%">
      <h1 class="title unselectable" style="margin: 20px 0"> SUPER MARIO MAKER <span class="secondarycolor"> INFO </span> </h1>
      <div class="search-container">
        <input class="unselectable" type="text" spellcheck="false" placeholder="ENTER LEVEL CODE" maxlength="11" v-on:input="updateSearchbar($event)" v-on:keyup.enter="getCourse()">
        <div class="search" v-on:click="getCourse()">
          <div class="centered">
            <div class="searchicon centered unselectable">
              <fa icon="search" style="height: 100%; width: 100%" v-if="!fetching"/>
              <fa class="rotating rotate" icon="sync-alt" style="height: 100%; width: 100%" v-if="fetching"/>
            </div>
          </div>
        </div>
      </div>
      <div class="button unselectable" v-on:click="toggleRecentSearches(!this.recentSearches.opened)">
        <div class="rotatable">
          <fa icon="caret-down" style="height: 100%; width: 100%;"/>
        </div>
        <h2> {{recentSearches.opened ? "Hide Recent Activity" : "Show Recent Activity"}} </h2>
        <div class="rotatable">
          <fa icon="caret-down" style="height: 100%; width: 100%;"/>
        </div>
      </div>
      <RecentSearches ref="RecentSearches" v-if="recentSearches.loaded"/>
    </div>
  </div>
  <div class="overlay fadein" v-if="levelmodal.opened"> <LevelModal v-click-outside="closeLevelModal"/> </div>
  <div class="infoicon fadein" v-if="!infomodal.opened && !levelmodal.opened" v-on:click="infomodal.opened = true">
    <div class="centered"></div>
    <fa icon="question-circle" style="width: 100%; height: 100%"/>
  </div>
  <div class="overlay fadein" v-if="infomodal.opened"> <InfoModal  v-click-outside="closeInfoModal"/> </div>
</template>


<script>
import StartAnimation from './components/StartAnimation.vue'
import LevelModal from './components/LevelModal.vue'
import InfoModal from './components/InfoModal.vue'
import RecentSearches from './components/RecentSearches.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    StartAnimation,
    LevelModal,
    InfoModal,
    RecentSearches
  },
  data() {
    return {
      levelmodal: {
        opened: false,
        levelThumbnail: null,
        courseData: {}
      },
      infomodal: {
        opened: false
      },
      recentSearches: {
        loaded: true,
        opened: false,
        datetime: null
      },
      fetching: false,
      noconnection: false,
      startAnimation: true
    }
  },
  methods: {
    updateSearchbar(e) {
      let code = e.target.value;
      e.target.value = this.FormatCode(code)
    },
    CheckValidCode(code) {
      if (!code.match('^[A-Za-z0-9]+$') || code.length == 0 || code == null)
        return false;
      return true;
    },
    FormatCode(code) {
      if (code == null)
        return '';
      code = code.replaceAll('-', '').replaceAll(' ', '').replaceAll('/', '').replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 9);
      if (code.length == 0)
        return '';
      console.log(code);
      let parts = code.match(/.{1,3}/g);
      return parts.join("-");
    },
    getCourse(courseCode = document.querySelector('input').value) {
      this.fetching = true;
      axios
        .get('api/level_info.php/?code=' + courseCode, {
        })
        .then(response => {
          this.fetching = false;
          if (response.data.error) {
            alert(response.data.error);
            return;
          }
          else if (response.data.detail) {
            alert(response.data.detail);
            return;
          }
          else {
            this.levelmodal.opened = true;
          }
          this.levelmodal.courseData = response.data;
          console.log("Traversed Pipes!");
          document.querySelector('input').value = courseCode;
        })
        .catch(error => {
          this.fetching = false;
          console.log('There was an error: ', error.response)

          console.log(error.response.data)
          alert("Something went wrong :(");
        })
    },
    closeLevelModal() {
      this.levelmodal.opened = false;
    },
    closeInfoModal() {
      this.infomodal.opened = false;
    },
    getLevelThumbnail(code) {
      return 'https://smm2.info/api/images/' + code[0] + '/' + code[1] + '/' + code + '.jpg';
    },
    getPrettyTime(milliseconds) {
      return new Date(milliseconds).toISOString().slice(14, -1);
    },
    toggleRecentSearches(opened) {
      if (opened && this.$refs.RecentSearches.courses.length == 0)
        this.$refs.RecentSearches.getRecentSearches();
      this.recentSearches.loaded = true;
      this.recentSearches.opened = opened;

      let arrows = document.querySelectorAll('.button > .rotatable');

      arrows.forEach(function(e) {
        if (this.recentSearches.opened)
          e.classList.add("rotated180")
        else
          e.classList.remove("rotated180")
      }.bind(this))

      let container = document.querySelector('.recentsearches')

      if (this.recentSearches.opened) {
        this.$nextTick(function () {
          let height = window.innerHeight * 0.9 - document.querySelector('.main > div').getBoundingClientRect().height;
          container.style.height = height + 'px';
        })
      }
      else {
        container.style.height = '0px';
      }
    }
  },
  created() {
    console.log("Oh yeah! Mario time!");
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)
      alert("This site might not work properly or look good on your iOS device, a fix is being worked on and using another browser might be a temporary fix.")
  },
  mounted() {
    axios.defaults.baseURL = 'https://smm2.info/'
    
    setTimeout(function() {
      this.startAnimation = false;
    }.bind(this), 2000);
    
    let urlParams = new URLSearchParams(window.location.search);
    let code = this.FormatCode(urlParams.get('code'));
    if (code != '')
      this.getCourse(code);
  }
}
</script>

<style>
  @import 'assets/css/app.css';
  @import 'assets/css/global_styles.css';
</style>