<template>
  <div class="infomodal scrollbar">
    <div class="topbar">
      <h1 class="infotitle centered">About/Info</h1>
      <div class="close" v-on:click="this.$parent.closeInfoModal()">
        <div class="centered">
          <fa icon="times" style="height: 100%; width: 100%; color: var(--white_text_color)"/>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
        SMM2 Info is a site created by me, <span class="secondarycolor">Keimpe</span>. <span style="font-size: 15px;"><fa icon="id-card"/> NTF-J3Q-THF</span>
        <br><br>
        For now, you can contact me on twitter at <a href="https://twitter.com/KiemPlant_" target="_blank">@KiemPlant_</a> for feature requests or other questions you might have.
        <br><br>
        The API this site relies on was provided by <span class="secondarycolor">TheGreatRambler</span>, also known as TGR Code on twitter at <a href="https://twitter.com/tgr_code" target="_blank">@tgr_code</a>. Without them it wouldn't have been possible.
        <br><br>
        Information displayed on this site is cached, it might take a while before the stats of a level update!
        <br><br>
        <span class="secondarycolor">Safari is currently NOT supported due to unforseen styling issues, sorry for that. I'm actively working on addressing this issue!</span>
        <br><br>
        Keep in mind that this site is still in development so it might not be as polished or feature rich as you might have expected!
        <br><br>
        There's definitely more to come in the future.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseData: null,
      players: []
    }
  },
  name: 'InfoModal',
  components: {
  },
  methods: {
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style>
  @import '../assets/css/infomodal.css';
</style>