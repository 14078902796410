<template>
  <div class="levelmodal centered">
    <div class="topbar">
      <h1 class="coursename centered">{{ courseData.name }}</h1>
      <div class="close" v-on:click="this.$parent.closeLevelModal()">
        <div class="centered">
          <fa icon="times" style="height: 100%; width: 100%"/>
        </div>
      </div>
    </div>
    <div class="content scrollbar flexcontainer">
      <div class="levelinfo flexgrow flexcontainer">
        <div class="thumbnail">
          <div class="p169"></div>
          <img v-bind:src="this.$parent.getLevelThumbnail(this.$parent.FormatCode(courseData.course_id))">
        </div>
        <div class="description" v-if="courseData.description != ''">
          <p> {{courseData.description}} </p>
        </div>
        <div class="rating flexcontainer">
          <div class="infoitem flexgrow flexcontainer">
            <div class="icon">
              <fa icon="user" style="height: 100%; width: 100%;"/>
            </div>
            <div class="flexcontainer" style="align-content: center">
              <h1> {{courseData.plays}} </h1>
            </div>
          </div>
          <div class="infoitem flexgrow flexcontainer">
            <div class="heart icon">
              <fa icon="heart" style="height: 100%; width: 100%;"/>
            </div>
            <div class="flexcontainer" style="align-content: center">
              <h1> {{courseData.likes}} </h1>
            </div>
          </div>
          <div class="infoitem flexgrow flexcontainer">
            <div class="icon">
              <fa icon="heart-broken" style="height: 100%; width: 100%;"/>
            </div>
            <div class="flexcontainer" style="align-content: center">
              <h1> {{courseData.boos}} </h1>
            </div>
          </div>
        </div>
        <div class="playstats flexcontainer">
          <div class="infoitem flexgrow flexcontainer">
            <div class="icon">
              <fa icon="flag" style="height: 100%; width: 100%;"/>
            </div>
            <div class="flexcontainer" style="align-content: center">
              <h1> {{courseData.clears}} </h1>
            </div>
          </div>
          <div class="infoitem flexgrow flexcontainer">
            <div class="icon">
              <fa icon="shoe-prints" style="height: 100%; width: 100%;"/>
            </div>
            <div class="flexcontainer" style="align-content: center">
              <h1> {{courseData.attempts}} </h1>
            </div>
          </div>
        </div>
        <div class="difficulty flexcontainer">
          <div class="clearrate infoitem flexgrow flexcontainer">
            <div class="flexcontainer" style="align-content: center">
              <h2 style="min-width: 80%;"> Clear Rate: <span class="secondarycolor">{{courseData.clear_rate}}</span></h2>
            </div>
          </div>
          <div class="infoitem flexgrow flexcontainer">
            <div class="flexcontainer" style="align-content: center; text-transform: capitalize;">
              <h2> Difficulty: <span class="secondarycolor">{{courseData.difficulty_name}}</span> </h2>
            </div>
            <div class="icon">
              <fa icon="grin-beam"  style="height: 90%; width: 90%;" v-if="courseData.difficulty == 0"/>
              <fa icon="smile-beam" style="height: 90%; width: 90%;" v-if="courseData.difficulty == 1"/>
              <fa icon="flushed"    style="height: 90%; width: 90%;" v-if="courseData.difficulty == 2"/>
              <fa icon="skull"      style="height: 90%; width: 90%;" v-if="courseData.difficulty == 3"/>
            </div>
          </div>
        </div>
        <div class="share flexcontainer flexcol">
          <div class="flexcontainer flexgrow">
            <div class="infoitem" style="height: 100%">
              <div class="flexcontainer" style="align-content: center; height: 100%;">
                <h2>Course Id: <span class="secondarycolor">{{this.$parent.FormatCode(courseData.course_id)}}</span></h2>
              </div>
            </div>
            <div class="sharebuttoncontainer">
              <div class="sharebutton icon unselectable" v-on:click="shareCourse()">
                <fa icon="share-alt" style="height: 90%; width: 90%;"/>
              </div>
            </div>
          </div>
          <div class="copyclipboard flexgrow" style="width: 100%;" v-if="linkcopied">
            Link has been copied to the clipboard!
          </div>
        </div>
      </div>
      <div class="playersinfo flexgrow">
        <div class="player flexcontainer flexcol" v-for="player in players" :key="player.data.code">
          <h2 style="text-transform: capitalize;"> {{player.type.replace('_', ' ')}}: <span class="secondarycolor"> {{player.data.name}} </span> <span class="owner"> {{player.data.code == 'NTFJ3QTHF' ? '(Site Owner)' : ''}} </span></h2>
          <div class="playerinfo flexgrow flexcontainer">
            <div class="playerstats flexgrow flexcontainer">
              <div>
                <div class="infoitem left flexcontainer" v-if="player.type == 'uploader'">
                  <div class="icon">
                    <fa icon="upload" style="height: 90%; width: 90%;"/>
                  </div>
                  <div class="flexcontainer" style="align-content: center">
                    <h2> {{courseData.uploaded_pretty.split(' ')[0]}} </h2>
                  </div>
                </div>
                <div class="infoitem left flexcontainer" v-if="player.type != 'first_completer'">
                  <div class="icon">
                    <fa icon="stopwatch" style="height: 90%; width: 90%;"/>
                  </div>
                  <div class="flexcontainer" style="align-content: center">
                    <h2> {{player.type == 'uploader' ? courseData.upload_time_pretty : courseData.world_record_pretty}} </h2>
                  </div>
                </div>
                <div class="infoitem left flexcontainer">
                  <div class="icon">
                    <fa icon="id-card" style="height: 90%; width: 90%;"/>
                  </div>
                  <div class="flexcontainer" style="align-content: center">
                    <h2> {{this.$parent.FormatCode(player.data.code)}} </h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="playerimage">
              <img v-bind:src="player.data.mii_image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      courseData: null,
      players: [],
      linkcopied: false
    }
  },
  name: 'LevelModal',
  components: {
  },
  methods: {
    shareCourse() {
      let title = "Check out this SMM2 Course!"
      let url = 'https://smm2.info/?code=' + this.$parent.FormatCode(this.courseData.course_id)

      if (navigator.share) {
        navigator.share({
          title: title,
          url: url
        }).then(() => {
          console.log('Sent course through red pipe!');
        }).catch(console.error)
      }
      else {
        navigator.clipboard.writeText(url)
        this.linkcopied = true;
      }
    }
  },
  created() {
    this.courseData = this.$parent.levelmodal.courseData;
    this.players.push({type: "uploader", data: this.courseData.uploader})
    if (this.courseData.record_holder != null) this.players.push({type: "record_holder", data: this.courseData.record_holder})
    if (this.courseData.first_completer != null) this.players.push({type: "first_completer", data: this.courseData.first_completer})
    // this.courseData.first_completer.code != this.courseData.record_holder.code ? this.players.push({type: "record_holder", data: this.courseData.record_holder}) : null
    // let type = this.courseData.first_completer.code != this.courseData.record_holder.code ? "first_completer" : "record_holder"
    // this.players.push({type: type, data: this.courseData.first_completer})
  },
  mounted() {
  }
}
</script>

<style>
  @import '../assets/css/levelmodal.css';
</style>